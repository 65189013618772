import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import '../App.css'; // Zorg ervoor dat de CSS wordt geïmporteerd

const Reviews = ({ setAverageRating }) => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        console.log("Fetching reviews...");
        const response = await fetch('/reviews.json');
        console.log("Response received:", response);
        const data = await response.json();
        console.log("Parsed data:", data);
        setReviews(data);

        // Bereken de gemiddelde beoordeling
        if (data.length > 0) {
          const totalRating = data.reduce((sum, review) => sum + review.rating, 0);
          const averageRating = totalRating / data.length;
          setAverageRating(averageRating);
        }
      } catch (error) {
        console.error("Kon de beoordelingen niet ophalen. Weergave van dummy data.", error);
        // Als er een fout optreedt, gebruik dummy data
        setReviews([
          {
            title: "Dummy Review",
            content: "Dit is een dummy review vanwege een fout bij het ophalen van de echte reviews.",
            author: "Systeem",
            date: "2023-01-01",
            rating: 3
          }
        ]);
      }
    };

    fetchReviews();
  }, [setAverageRating]);

  if (reviews.length === 0) {
    return <div>Geen beoordelingen beschikbaar.</div>;
  }

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section className="reviews">
      <h2>Beoordelingen</h2>
      <Slider {...settings}>
        {reviews.map((review, index) => (
          <div key={index} className="review">
            <div className="review-header">
              <p><strong>{review.author}</strong></p>
              <p>{new Date(review.date).toLocaleDateString()}</p>
              <div className="stars">
                {'★'.repeat(review.rating) + '☆'.repeat(5 - review.rating)}
              </div>
            </div>
            <div className="review-content">
              <h3>{review.title}</h3>
              <p>{review.content}</p>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
}

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#ff6347", borderRadius: "50%" }}
      onClick={onClick}
    />
  );
}

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#ff6347", borderRadius: "50%" }}
      onClick={onClick}
    />
  );
}

export default Reviews;
