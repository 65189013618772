import React from 'react';


const Introduction = () => {
  return (
    <section className="introduction">
      <div className="intro-text">
        <h2>Over onze Casa Buenaventura</h2>
      <p>

      Onze villa is gelegen in de wijk Cabanyes-Calonge, op slechts 5 minuten rijden van het centrum van Calonge en de prachtige stranden van de Costa Brava. Vanaf hier kun je gemakkelijk de mooie stranden van zowel Calonge als de omliggende kustplaatsen verkennen. Onze buurt barst van de gezellige restaurants met een gevarieerde keuken, zodat er voor ieder wat wils is.
Vanaf het terras heb je een prachtig uitzicht over de haven van Palamos terwijl je barbequet of een duik neemt in het nieuwe zwembad. Voor de frissere dagen kun je plaats nemen in het winterblijf voor een adembenemend uitzicht.
In de woonkamer staat een grote eettafel met plek voor 8 gasten, samen met een comfortabele bank met een tv met een chrome cast, zodat je je eigen series en films makkelijk vanaf je telefoon kan streamen. Koken is te doen in de binnen- en buitenkeuken, en zijn beide voorzien met een vaatwasser en koelkast. Het huis heeft 3 slaapkamers en 1 studio ter hoogte van het terras., waarbij 1 slaapkamer een ensuite badkamer heeft, net zoals de studio. Er is 1 gedeelde badkamer op de bovenste verdieping.
      </p>

        {/* <p>
We are very pleased that you are using our holiday villa, and hope that you fall in love with the Costa Brava, just like we did!
Leave the worries of everyday life behind and immerse yourself in our house and its fantastic surroundings.
Discover small beaches, nice lunch-spots, interesting boutiques and stroll through  cobble stone paved streets in centuries-old cities. Put on your walking shoes and walk a part of a camino along the coast or visit the botanic gardens of CapRoig.
Rest and enjoy the views, watch the lights come on in Palamos in the evening while you swim a lap in the infinity pool.
Play Joules de Boules and watch the sun's shine on the bay of Palamos.
Have good conversations while sitting on the rustic stone bench, while the sky turns into purple.
Our doors are open for  you. If there is anything we can help you with, please don't hesitate to contact us or Sam.
We want you to feel at home,  and think back on these days with a smile.
We have done everything we can to make this happen.
To an unforgettable holiday full of precious memories!</p> */}
      </div>
      
    </section>
  );
}

export default Introduction;
