import React from 'react';
import '../App.css'; // Zorg ervoor dat dit pad correct is

const Location = () => {
  return (
    <div className="location section">
      <h2>De omgeving</h2>
      <div className="map-container">
        <iframe
          title="location-map"
          src={`https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12310.511203981!2d3.05592!3d41.85332!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0:0x0!2z41uNTDEzIHMgMyAwMyA1NS45MiJF!5e0!3m2!1sen!2sus!4v1697829022512!5m2!1sen!2sus`}
          width="100%"
          height="400"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
}

export default Location;
