import React from 'react';
import heroImage from '../img/House/House1.JPG'; // Zorg ervoor dat dit pad klopt

const Hero = () => {
  return (
    <section className="hero" style={{ backgroundImage: `url(${heroImage})` }}>
    </section>
  );
}

export default Hero;
