import React, { useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import House2 from '../img/House/House2.JPG';
import House3 from '../img/House/House3.JPG';
import Bathroom from '../img/House/Bathroom.jpeg';
import SwimP from '../img/House/SwimP.jpeg';
import SwimP1 from '../img/House/SwimP1.jpeg';
import Resident from '../img/House/Resident.jpeg';
import Entrance from '../img/House/Ingang.jpeg';
import Terras from '../img/House/Ingang.jpeg';
import Terras2 from '../img/House/Terras2.jpeg';
import View from '../img/House/View.jpeg';
import Loungeset from '../img/House/Loungeset.jpeg';


function Gallery() {
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const images = [House2, House3, Bathroom, SwimP, SwimP1, Resident, Entrance, Terras, Terras2, View, Loungeset ];
  const visibleImages = images.slice(0, 3);
  const remainingImagesCount = images.length - visibleImages.length;

  return (
    <section className="gallery">
      <h2>Gallery</h2>
      <div className="gallery-images">
        {visibleImages.map((src, index) => (
          <img 
            key={index}
            src={src}
            alt={`Image ${index + 1}`}
            onClick={() => { setOpen(true); setCurrentImage(index); }}
          />
        ))}
        {remainingImagesCount > 0 && (
          <div 
            className="show-more-img" 
            onClick={() => { setOpen(true); setCurrentImage(visibleImages.length); }}
            style={{ backgroundImage: `url(${images[3]})` }}
          >
            <span>+{remainingImagesCount}</span>
          </div>
        )}
      </div>
      {open && (
        <Lightbox 
          slides={images.map(src => ({ src }))}
          open={open}
          index={currentImage}
          close={() => setOpen(false)}
        />
      )}
    </section>
  );
}

export default Gallery;
