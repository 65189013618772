import React, { useState } from 'react';
import '../App.css'; 

const amenitiesData = [
  {
    category: 'Sport en Recreatie',
    items: ['Fietsen', 'Wandelen', 'Padel 3,9km', 'Zwemmen 5,3km(strand)', 'Tennis 3,9km']
  },
  {
    category: 'Internet, wifi en audio',
    items: ['Draadloos internet, glasvezel', 'Streaming (Google Chrome cast)', 'Televisie', 'Sonos Audio (Boven en beneden)']
  },
  {
    category: 'Buitenvoorzieningen',
    items: ['Balkon', 'Terras', 'Zwembad', 'Tuinstoelen x11', 'Tuintafel', 'Loungesets x4', 'Barbeque', 'Buitenverlichting', 'Buitenkeuken', 'Jeu de Boules', 'Tuin afgesloten']
  }
];

const Amenities = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleCategory = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="amenities section">
      <h2>Voorzieningen</h2>
      <div className="amenities-list">
        {amenitiesData.map((amenity, index) => (
          <div
            key={index}
            className={`amenities-category ${activeIndex === index ? 'active' : ''}`}
            onClick={() => toggleCategory(index)}
          >
            <h3>{amenity.category}</h3>
            <ul>
              {amenity.items.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Amenities;
