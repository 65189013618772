import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import casaImg from '../img/Casa.png';

const Header = () => {
  return (
    <nav>
      <ul>
        <li>
          <img src={casaImg} alt="Casa" style={{ height: '150px' }} />
        </li>
        <li><ScrollLink to="introduction" smooth={true} duration={500}>Over Ons</ScrollLink></li>
        <li><ScrollLink to="gallery" smooth={true} duration={500}>Galerij</ScrollLink></li>
        <li><ScrollLink to="location" smooth={true} duration={500}>Informatie</ScrollLink></li>
        <li><ScrollLink to="reviews" smooth={true} duration={500}>Beoordelingen</ScrollLink></li>
        <li><ScrollLink to="calendar" smooth={true} duration={500}>Beschikbaarheid</ScrollLink></li>
       
      </ul>
    </nav>
  );
}

export default Header;
