import React from 'react';
import '../App.css'; // Zorg ervoor dat je CSS is geïmporteerd
import ownersImg from '../img/AboutUs.jpg'

const Owners = () => {
  return (
    <div className="owners">
      <div className="owners-text">
        <p>
          Wij zijn 2 liefhebbers van de Costa Brava en hebben hier dan ook een aantal vakanties met plezier door mogen brengen, of het is om te wandelen, of om te duiken, de Costa Brava biedt genoeg mogelijkheden voor iedereen!
        </p>
      </div>
      <div className="owners-photo">
        <img src={ownersImg} alt="Erica en Michiel" />
        <p>Erica & Michiel</p>
      </div>
    </div>
  );
}

export default Owners;
