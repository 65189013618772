import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import Introduction from './components/Introduction';
import Gallery from './components/Gallery';
import Owners from './components/Owners';
import Location from './components/Location';
import Information from './components/Information';
import Amenities from './components/Amenities';
import Reviews from './components/Reviews';
import AvailabilityCalendar from './components/Calendar';
import Footer from './components/Footer';
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function App() {
  const [averageRating, setAverageRating] = useState(0);

  return (
    <div>
      <Routes>
        <Route path="/" element={
          <>
            <Header />
            <main>
              <Hero />
              <Introduction />
              <Owners />
              <Gallery />
              <Location />
              <Information />
              <Amenities />
              <Reviews setAverageRating={setAverageRating} />
              <AvailabilityCalendar />
            </main>
            <Footer averageRating={averageRating} />
          </>
        } />
      </Routes>
    </div>
  );
}

export default App;
